.root {
  .product {
    padding: 24px;
    flex-grow: 1;
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    .product__img__container {
      min-width: 250px;
      max-width: 100%;
      min-height: 250px;
      max-height: 750px;
      aspect-ratio: 1/1;
      &:hover {
        .product__img {
          img {
            transform: scale(1.05);
          }
        }
      }
      .product__img {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 48px;
        border-radius: 16px;
        background-image: linear-gradient(#00000020, #00000000);
        overflow: hidden;
        width: 100%;
        height: 100%;
        img {
          object-fit: contain;
          object-position: center;
          width: 100%;
          height: 100%;
          transition: transform 0.3s;
        }
      }
    }

    .product__details {
      display: flex;
      gap: 16px;
      flex-direction: column;
      align-content: flex-start;
      .product__title {
        margin: 0;
        font-size: 2.8rem;
      }
      .product__description {
        flex-grow: 1;
        color: #666;
        font-size: 1.3rem;
      }
      .product__price {
        font-size: 1.8rem;
        //   align-self: center;
      }
      .product__quantity {
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        .quantity {
          display: flex;
          gap: 16px;
          align-items: center;
          .value__q {
            flex-grow: 1;
            height: 48px;
            border: 2px solid #333;
            border-radius: 10px;
            aspect-ratio: 3 / 2;
            > input {
              display: flex;
              align-items: center;
              justify-content: center;
              border: none;
              outline: none;
              border-radius: 10px;
              width: 100%;
              height: 100%;
              padding-inline-start: 22px;
              font-size: 1.4rem;
            }
          }
          .plus__q,
          .minus__q {
            svg {
              width: 28px;
            }
          }
        }
        .product__actions {
          font-size: 1.5rem;
          //   grid-column: 2 / span 2;
          justify-self: flex-start;
          // padding-inline: 0;
          white-space: nowrap;
        }
      }
    }
  }
  .product__recommended {
    // grid-row: 2;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    .recommended__title {
      font-size: 2rem;
      padding-inline: 24px;
    }
    .list__recommended {
      width: 100%;
      overflow: hidden;
      padding-block: 16px;
      .recommended {
        padding-inline: 24px;
        display: flex;
        gap: 16px;
        overflow-x: auto;
        padding-block: 16px;
        .recommended__card {
          // margin: 12px;
          min-width: 260px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .root {
    .product {
      padding: 16px;
      gap: 16px;
      .product__details {
        gap: 12px;
        .product__title {
          font-size: 2rem;
        }
        .product__description {
          font-size: 1.1rem;
        }
        .product__price {
          font-size: 1.4rem;
        }
        .product__quantity {
          gap: 8px;
          .quantity {
            display: flex;
            gap: 8px;
            align-items: center;
            .value__q {
              flex-grow: 1;
              height: 36px;
              > input {
                padding-inline-start: 16px;
                font-size: 1.2rem;
              }
            }
            .plus__q,
            .minus__q {
              svg {
                width: 24px;
              }
            }
          }
          .product__actions {
            font-size: 1.2rem;
          }
        }
      }
    }
    .product__recommended {
      .recommended__title {
        padding-inline: 16px;
      }
      .list__recommended {
        .recommended {
          padding-inline: 16px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .root {
    .product {
      display: block;
      .product__details {
        gap: 12px;
        padding: 16px;
        .product__title {
          font-size: 1.8rem;
        }
        .product__description {
          font-size: 1rem;
        }
        .product__price {
          font-size: 1.2rem;
        }
        .product__quantity {
          gap: 8px;
          .quantity {
            display: flex;
            gap: 4px;
            align-items: center;
            .value__q {
              flex-grow: 1;
              height: 36px;
              > input {
                padding-inline-start: 16px;
                font-size: 1.1rem;
              }
            }
            .plus__q,
            .minus__q {
              svg {
                width: 22px;
              }
            }
          }
          .product__actions {
            font-size: 1rem;
          }
        }
      }
    }
    .product__recommended {
      .recommended__title {
        font-size: 1.7rem;
        padding-inline: 32px;
      }
    }
  }
}

// @media (max-width: 576px) {
//   .root {
//     .product {
//       .product__details {
//         .product__quantity {
//           // justify-content: space-between;
//         }
//       }
//     }
//   }
// }
