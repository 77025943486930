.card {
  overflow: hidden;
  position: relative;
  max-width: 100%;
  background-image: linear-gradient(#00000020, #00000000);
  border-radius: 16px;
  padding: 16px 20px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  &:active,
  &:hover {
    .card__img {
      transform: scale(1.1);
    }
  }
  .show__details {
    overflow: hidden;
    position: absolute;
    border-radius: 16px;
    border-start-start-radius: 0px;
    border-start-end-radius: 0px;
    top: 0;
    left: 50%;
    transition: transform 0.3s;
    transform: translate(-50%, -120%);
    a {
      font-size: 1.1rem;
    }
  }
  &:active,
  &:hover {
    // transform: scale(1.05);
    .show__details {
      transform: translate(-50%, 0);
    }
  }
  .card__img {
    height: 300px;
    min-width: 200px;
    margin: 16px 8px 24px;
    overflow: hidden;
    transition: transform 0.3s;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
  .card__details {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-block: 32px;
    justify-content: space-between;
    align-items: flex-start;
    .card__title {
      font-size: 2.8rem;
    }
    .card__description {
      flex-grow: 1;
      color: #666;
      font-size: 1.2rem;
    }
    .card__price {
      font-size: 1.4rem;
      //   align-self: center;
    }
    .card__actions {
      font-size: 1.2rem;
      //   grid-column: 2 / span 2;
      justify-self: flex-start;
      padding-inline: 0;
      white-space: nowrap;
    }
  }
}

@media (max-width: 768px) {
  .card {
    min-height: 500px;
    display: flex;
    flex-direction: column;
    .show__details {
      a {
        font-size: 1rem;
      }
    }
    .card__img {
      height: 200px;
    }
    .card__details {
      padding-block: 8px;
      flex-grow: 1;
      .card__title {
        font-size: 1.8rem;
      }
      .card__description {
        font-size: 1rem;
      }
      .card__price {
        font-size: 1.1rem;
      }
      .card__actions {
        font-size: 1rem;
      }
    }
  }
}
