.header {
  height: 64px;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  position: sticky;
  background-color: #fff;
  .navbar {
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .title {
      // flex-grow: 1;
      display: flex;
      align-items: center;
      transform: translateY(-13px);
      img {
        width: 160px;
      }
    }
    .grow {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      padding-inline-end: 4px;
      align-items: center;
      .search__menu {
        display: none;
      }
      .search {
        display: flex;
        align-items: center;
        flex-grow: 1;
        overflow: hidden;
        margin-inline: 32px;
        border-radius: 50px;
        background-image: linear-gradient(#dfdfdf, #efefef);
        input {
          padding-inline: 20px;
          margin-block-start: 8px;
          margin-block-end: auto;
          width: 100%;
          border: none;
          outline: none;
          background-color: transparent;
          font-size: 1.1rem;
          &::placeholder {
            color: var(--disabled);
            font-size: 1rem;
          }
          &::-webkit-search-cancel-button {
            font-size: 24px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.drawer__btn {
}

.drawer {
  padding: 20px;
  .drawer__list {
    padding-inline-end: 4px;
    .drawer__title {
      width: max-content;
      margin-block-end: 8px;
      h2 {
        position: relative;
        padding-block-end: 6px;
        color: var(--main);
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          border-radius: 50%;
          left: 0;
          bottom: 0;
          background-color: goldenrod;
          // transform: scale(1.2);
          // background-color: #333;
          background-image: linear-gradient(90deg, goldenrod, gold);
        }
        // border-bottom: ;
      }
    }
    .drawer__anchor {
      margin-block: 1px;
    }
  }
}

@media (max-width: 768px) {
  .header {
    .navbar {
      .grow {
        position: relative;
        .search__menu {
          display: block;
        }
        .search {
          margin: 0;
          position: fixed;
          top: -100px;
          left: 10vw;
          width: 80vw;
          transition: top 0.4s;
          box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.4);
          &.active {
            top: 70px;
          }
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .header {
    .navbar {
      .title {
        transform: translateY(-7px);
        img {
          width: 128px;
        }
      }
    }
  }
}
