.card {
  overflow: hidden;
  position: relative;
  max-width: 100%;
  background-image: linear-gradient(#00000020, #00000005);
  border-radius: 16px;
  padding: 16px 20px;
  // margin: 12px;
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.3s;
  &:active,
  &:hover {
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.3);
    .show__details {
      transform: translateY(0);
    }
    .card__img {
      transform: scale(1.1);
    }
  }
  .show__details {
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.3s;
    transform: translateY(-120%);
  }
  .card__img {
    height: 140px;
    margin: 16px 8px 24px;
    overflow: hidden;
    transition: transform 0.3s;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
  .card__details {
    display: grid;
    flex-grow: 1;
    gap: 4px;
    justify-content: space-between;
    grid-template-columns: auto 1fr 1fr;
    grid-template-rows: auto 1fr auto;
    .card__title {
      grid-row: 1;
      grid-column: 1 / span 3;
    }
    .card__description {
      grid-row: 2;
      grid-column: 1 / span 3;
      min-height: 52px;
      color: #666;
      font-size: 0.95rem;
    }
    .card__price {
      grid-row: 3;
      align-self: center;
    }
    .card__actions {
      grid-row: 3;
      grid-column: 2 / span 2;
      justify-self: flex-end;
      white-space: nowrap;
    }
  }
}
