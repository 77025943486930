.card {
  color: inherit;
  display: flex;
  flex-direction: column;
  width: 240px;
  height: 240px;
  // border-radius: 50% !important;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(#00000000, #00000030),
    linear-gradient(-30deg, #ffffff00 50%, rgba(#1976d2, 1) 50%);
  // margin: 12px 32px;
  box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.1);
  padding: 32px;
  transition: all 0.3s;

  &:active,
  &:hover {
    box-shadow: 0 0 10px 3px rgba($color: goldenrod, $alpha: 0.8);
    .card__image {
      animation: floating infinite 2s;
      animation-delay: 0.1s;
    }
  }
  .card__image {
    height: 120px;
    z-index: 2;
    overflow: hidden;
    transition: transform 0.3s;
    img {
      object-fit: contain;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }
  .card__title {
    z-index: 2;
    display: flex;
    align-items: center;
    margin-inline: 12px;
    flex-grow: 1;
    h3 {
    }
  }
}

@keyframes floating {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}
