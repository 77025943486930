.root {
  .products {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 16px;
    .grid__container {
      width: 100%;
      flex-grow: 1;
      padding-block: 16px;
      .grid {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
        gap: 16px;
      }
    }
  }
}
