.container {
  padding-block: 24px;
}

.title {
  margin-bottom: 16px !important;
  padding-inline: 24px;
}

.empty__cart {
  padding-inline: 28px;
}

.products__container {
  width: 100%;
  overflow: hidden;
  .products {
    display: flex;
    overflow-x: auto;
  }
}

.cart__details {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  padding-inline: 24px;
  width: 100%;
  justify-content: space-between;

  > h4 {
    margin: 0 10px 10px;
  }

  .cart__details__btns {
    justify-self: flex-end;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    .empty__btn {
      min-width: 150px;
      margin: 0 10px 10px;
    }

    .checkout__btn {
      min-width: 150px;
      margin: 0 10px 10px;
    }
  }
}

@media (max-width: 768px) {
  .container {
    padding-block: 16px;
  }

  .title {
    margin-bottom: 16px !important;
    padding-inline: 16px;
  }

  .empty__cart {
    padding-inline: 20px;
  }

  .cart__details {
    padding-inline: 12px;
  }
}
