.home {
  //   display: grid;
  //   grid-template-columns: 1fr 1fr 1fr;
  .carousel {
    margin: 24px;
  }
  .categories {
    margin: 32px 24px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 24px;
    justify-items: center;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .home {
    //   display: grid;
    //   grid-template-columns: 1fr 1fr 1fr;
    .carousel {
      margin: 16px;
    }
    .categories {
      margin: 16px 8px;
      gap: 16px;
    }
  }
}
