:root {
  --normal_font: #1a1c1e;

  --main: #1976d2;

  --sub: gold;
  --sub_dark: goldenrod;

  --bg_main: white;

  --disabled: gray;
}

$normal_font: #1a1c1e;

$main: #1976d2;

$sub: gold;
$sub_dark: goldenrod;

$bg_main: white;

$disabled: gray;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $main;
  }

  scrollbar-color: $main transparent;
}

body {
  overflow-x: hidden;
  // margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 0.3s;
  color: var(--normal_font);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// button,
.anchor__colored {
  cursor: pointer;
  border-radius: 50px;
  text-decoration: none;
  color: inherit;
  &.active,
  &:focus,
  &:active,
  &:hover {
    color: var(--main);
  }
}

ul {
  list-style: none;
}

// button {
//   border: none;
//   background: none;
//   cursor: pointer;
// }

.error__text {
  color: red;
  padding-inline-start: 6px;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  > :nth-child(2) {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}

.container {
  margin-inline: auto;
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1160px;
  }
}
@media (min-width: 1440px) {
  .container {
    max-width: 1380px;
  }
}
@media (min-width: 1600px) {
  .container {
    max-width: 1520px;
  }
}
