.card {
  flex-shrink: 0;
  max-width: 250px;
  min-width: 200px;
  margin: 12px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: linear-gradient(#00000020, #00000005);

  &:active,
  &:hover {
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.3);
    .card__img {
      transform: scale(1.1);
    }
  }
  .card__img {
    padding: 32px 32px 16px;
    overflow: hidden;
    transition: transform 0.3s;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
  .card__content {
    justify-self: flex-end;
    display: grid;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    // justify-content: space-between;
    // align-items: flex-end;
    .card__title {
      grid-row: 1 / span 1;
      grid-column: 1 / span 2;
    }
    .card__price {
      grid-row: 2 / span 1;
      grid-column: 1 / span 1;
    }
    .remove__btn {
      grid-row: 3 / span 1;
    }
    .card__quantity {
      grid-row: 2 / span 2;
      grid-column: 2 / span 1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      .quantity {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
